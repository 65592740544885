<template>
  <div class="common-bg">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
    <div class="detail">
      <div class="layout">
        <div class="add-type3">
          <div class="type3-img">
            <img src="@/assets/images/ymh.webp" style="width: 30; height: 30;" alt="">
          </div>
        </div>
        <div class="padded-15">
          <div class="title"></div>
          <div class="padded-t-15">
            <van-row >
              <van-col span="12" style="text-align: left">
                <div class="text-black font-size-16 font-weight">
                {{num}}
                </div>
                <div class="font-size-12 text-grey padded-t-5">当前数量</div>
              </van-col>
            </van-row>

          </div>
        </div>
      </div>

      <div class="padded-15 text-danger" >

        <div class="btn padded-t-15" >
          <van-button type="warning" block @click="onSubmit()">
            开启魔盒
          </van-button>
        </div>
      </div>
    </div>
  </div>
  <!-- <van-popup
    v-model:show="passwordShow"
    round
    position="bottom"
    :style="{ height: '380px', background: '#f2f3f5' }"
  >
    <div class="padded-15 text-center">输入支付密码</div>
    <van-password-input
      :value="payPassword"
      :gutter="10"
      info="密码为 6 位数字"
      :error-info="errorInfo"
      :focused="showKeyboard"
      @focus="showKeyboard = true"
    />
    <van-number-keyboard v-model="payPassword" :show="showKeyboard" />
  </van-popup> -->
</template>

<script>
import {
  getProductDetail,
  checkPayPwdIsCorrect,
  submitOrder,
  submitOrder1,
  openLuckMh,
  getMhList
} from '@/utils/api.js'
export default {
  name: 'info',
  data() {
    return {
      productId: this.$route.query.id,
      productType: this.$route.query.type,
      title: '详情',
      step: 1,
      ymh: 1,
      jmh: 2,
      num:0,
      data: {},
      payPassword: '',
      errorInfo: '',
      showKeyboard: true,
      passwordShow: false,
      showPay: false,
      checkPay: 0,
	    day:0,
      dataPay: [
        { label: '支付宝', value: 'alipay' },
				{ label: '微信', value: 'weixin' }
      ]
    }
  },
  watch: {
    payPassword(value) {
      if (value.length > 6) {
        return
      }
      if (value.length === 6) {
        this.$toast.loading({
          duration: 0,
          message: '提交中...',
          forbidClick: true
        })
        setTimeout(() => {
          this.checkPayPwdIsCorrect()
        }, 1500)
      }
    }
  },
  created() {
    this.setTitle()
    this.getDetail()
  },
  computed: {
    totalPrice: function () {
      let price = 0
      //   console.log(this.productType)
      console.log(this.step)
      if (this.productType === '3') {
        price = this.num * this.data.price
        return price.toFixed(2)
      } else {
        // if (this.step === 1) {
        price = this.num * this.data.price
        return price.toFixed(2)
        // } else {
        //   price = this.num * this.data.price
        //   return (price / 100).toFixed(2)
        // }
      }
    },
    totalFenHong: function () {
      let fenhong = 0
      //   if (this.step === 1) {
      fenhong = this.num * this.data.fenhong
      return fenhong.toFixed(2)
      //   } else {
      //     fenhong = this.num * this.data.fenhong
      //     return (fenhong / 100).toFixed(2)
      //   }
    }
  },
  methods: {
    getDetail() {
      getMhList().then((res) => {
        this.data = res.data
        this.num = res.data.mh_num
        this.min = JSON.parse(JSON.stringify(res.data.num))
        this.step = 1
      })
    },
    confirmPassword() {
      this.passwordShow = true
    },
    // 检测支付密码是否正确
    checkPayPwdIsCorrect() {
      const param = { payPassword: this.payPassword }
      checkPayPwdIsCorrect(param).then((res) => {
        if (res.code === 200) {
          if (res.data === 'true') {
            this.onSubmit()
          } else {
            this.payPassword = ''
            this.$toast(res.message)
          }
        } else {
          this.$toast(res.message)
        }
      })
    },
    //开金魔盒
    onSubmit() {

      const param = {

        order_id: this.ymh,
      }
      this.$toast.loading({
        duration: 0,
        message: '提交中...',
        forbidClick: true
      })
      setTimeout(() => {
        openLuckMh().then((res) => {
          this.$toast.clear()
          if (res.code === 200) {
           // window.location.href = res.message
            this.$toast('购买成功')
             return
          } else {
            this.$toast(res.message)
            if(res.message === '请先实名') this.$router.push('/home/realName')
          }
        })
      }, 1700)
    },
    //开金魔盒
    onSubmit2() {

      const param = {

        order_id: this.jmh,
      }
      this.$toast.loading({
        duration: 0,
        message: '提交中...',
        forbidClick: true
      })
      setTimeout(() => {
        openLuckMh().then((res) => {
          this.$toast.clear()
          if (res.code === 200) {
           // window.location.href = res.message
            this.$toast('购买成功')
             return
          } else {
            this.$toast(res.message)
            if(res.message === '请先实名') this.$router.push('/home/realName')
          }
        })
      }, 1700)
    },
    // 检测支付密码是否正确线上支付
    checkPayPwdIsCorrect() {
      const param = { payPassword: this.payPassword }
      checkPayPwdIsCorrect(param).then((res) => {
        if (res.code === 200) {
          if (res.data === 'true') {
            this.onSubmit1()
          } else {
            this.payPassword = ''
            this.$toast(res.message)
          }
        } else {
          this.$toast(res.message)
        }
      })
    },
    onSubmit1() {
      if (this.productType === '3') {
        this.totalFenHong = 0
      }
      const param = {
        productId: this.data.id,
        money: this.totalPrice,
        num: this.num,
        fenhong: this.totalFenHong,
        paytype: this.dataPay[this.checkPay].value
      }
      this.$toast.loading({
        duration: 0,
        message: '提交中...',
        forbidClick: true
      })
      setTimeout(() => {
        submitOrder1(param).then((res) => {
          this.$toast.clear()
          if (res.code === 200) {
            window.location.href = res.message
          } else {
            this.$toast(res.message)
            if(res.message === '请先实名') this.$router.push('/home/realName')
          }
        })
      }, 1700)
    },
    setTitle() {
      if (this.productType === 1) {
        this.title = '股权详情'
      } else if (this.productType === 2) {
        this.title = '基金详情'
      }
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  .layout {
    background: #ffffff;
    margin: 15px;
    border-radius: 10px;
    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 148px;
      width: 100%;
    }
    .title {
      color: #2a2a2a;
      font-size: 16px;
      font-weight: 700;
    }
  }
  .info-box {
    width: 343px;
    background: #fff;
    box-shadow: 0 16px 40px -4px rgb(243 142 142 / 8%);
    border-radius: 8px;
    margin: 20px auto;
    .info-li {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .li-l {
        font-size: 14px;
        color: #999;
      }
      .li-r {
        display: flex;
        align-items: center;
      }
    }
  }
  .add-type3 {
    display: flex;
    padding: 17px 17px 0;
    align-items: center;
    .type3-img {
      margin: auto;
    }
    .layout-img{
      margin: auto;

    }
  }
}
.pay {
  padding: 10px;
}
</style>
